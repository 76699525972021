<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技荣获“2023脉脉年度行业观察者”称号
              </p>
              <div class="fst-italic mb-2">2023年2月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >品牌分享会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/28/01-分享会.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸科技人力资源总监黄嘉轩受邀出席脉脉高聘雇主品牌分享会，并荣获“2023脉脉年度行业观察者”称号。非凸科技将持续为量化行业输出敏锐洞察和经验分享，在思想碰撞中吸引更多优秀人才。
                  </p>
                   <p>
                    分享会上，脉脉副总裁、人脉业务负责人定胜斌分享了企业如何打造雇主品牌，以及如何加速中高端人才组织建设，并表示脉脉高聘2023年将为更多企业吸纳中高端人才提供助力。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/28/02-合集.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    非凸科技自成立以来，经历了多轮算法迭代和研发演进的过程，将业务理解和技术创新发挥到极致，为机构业务发展长期赋能加速。技术、业务上的创新离不开非凸科技的务实前行，也离不开其组织架构、企业文化上的不断探索与优化。业务发展的同时，非凸科技在持续招募优秀量化人才，提供有竞争力的薪资待遇，打造个性化的发展路径，以及鼓励非凸人敢于创新、勇于尝试。
                  </p>
                  <p>
                    目前，非凸科技已在多地设立分支机构，形成多业务模式协同发展，组织规模进一步扩大。与此同时，业务复杂程度也在不断提升，需要更敏捷的组织，以及更先进、体系化的管理工具和机制，来支撑当下和未来的快速发展。
                  </p>
                  <p>
                    非凸科技一直坚持雇主品牌建设，吸引适配业务发展的优秀人才。与脉脉合作，利用其社区和招聘优势，可以让非凸科技更高效更精准地连接中高端人才。感谢脉脉授予的“2023脉脉年度行业观察者”称号！非凸科技将在脉脉职场社区继续分享前沿技术、量化洞察、职场经验等内容，不断精进，让大家在职场社交的同时也能收获到满满干货。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/28/03-奖项.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    每一份肯定的背后，离不开大家的信任和支持。新的一年，非凸科技将持续加强雇主品牌建设，吸引更多优秀人才加入。在非凸，你可以在机构业务中探索多维度实战机会，在平等协作氛围中激发自身无穷潜力，也可以在丰富学习实践中收获更多成长！
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News28",
};
</script>

<style></style>
